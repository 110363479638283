<script>
import { Line } from 'vue-chartjs'
import _ from 'lodash'

export default {
  extends: Line,
  name: 'PatientsUsageChart',
  props: ['patients'],
  data () {
    return {
      options: {
        title: {
          display: true,
          text: 'Temps moyen d\'utilisation / jour d\'usage (sur 7 jours)'
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
              callback (value) {
                return value + 'h'
              }
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  computed: {
    labels () {
      const last90days = this.getLastDays(91, { day: 'numeric', month: '2-digit' })
      return _.chunk(last90days, 7).map(days => {
        return days.reduce((acc, day, index) => {
          if (index === 0) return acc + day
          else if (index === days.length - 1)  return acc + ' - ' + day
          else return acc
        }, '')
      })
    },
    usage () {
      return this.patients.map(patient => (patient.computedStats ? patient.computedStats.usageLast7Days : 0) )
    },
    patientsColors () {
      return this.patients.map(patient => patient.color)
    },
    chartData () {
      return {
        labels: this.labels,
        datasets: this.patients.map(patient => ({
          label: patient.firstName ? [patient.firstName, patient.lastName].join(' ') : patient.name,
          borderColor: patient.color,
          backgroundColor: patient.color,
          fill: false,
          data: patient.computedStats ? patient.computedStats.usageLast90days : []
        }))
      }
    }
  },
  methods: {
    getLastDays (count, format) {
      const dates = [...Array(count)].map((_, i) => {
        const d = new Date()
        d.setDate(d.getDate() - i)
        return d.toLocaleString('fr', format)
      })
      return dates.reverse()
    }
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
